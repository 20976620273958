export default {
    // 正式线
    baseurl: 'https://opapi.jsdnzydlkj.com', //接口地址
    storeurl: 'https://operation.jsdnzydlkj.com', //门店正式线地址
};

// 正式线
export const ServicePath = 'https://operation.jsdnzydlkj.com/'; //客服地址
export const path = 'wss://api.jsdnzydlkj.com/wss?access_token='; //socket地址

export const imgUrlHead = 'https://static.jsdnzydlkj.com';
