import Home from '../../views/Home.vue';
export default {
    path: '/rights',
    name: 'rights',
    component: Home,
    redirect: '/rights/settledList',
    meta: {
        name: '权益',
        title: '权益管理',
        rules: 1,
    },
    children: [
        {
            path: '/rights/settledList',
            name: 'rights-settledList',
            component: () => import('@/views/rights/SettledList'),
            meta: {
                name: '入驻列表',
                rules: 1,
            },
        },
        {
            path: '/rights/advertisementApply',
            name: 'rights-advertisementApply',
            component: () => import('@/views/rights/AdvertisementApply'),
            meta: {
                name: '广告申请',
                rules: 1,
            },
        },
        // 都能修

    ],
}
